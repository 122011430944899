import React from 'react';
import { FaCheck } from "react-icons/fa";
import * as styles from "./stripe.module.css";
import util from '../../../js/util';
import { useSelector } from 'react-redux';
import BigMessage from "../bigmessage/bigmessage"

const PricingTable = ({isFree}) => {
    const email = useSelector((state) => state.profile.email)
    const priceTableId = useSelector((state) => state.stripe.priceTableId)
    const publishableKey = useSelector((state) => state.stripe.publishableKey)
    const customerPortal = useSelector((state) => state.stripe.customerPortal)

    const subscriptionMgmtHandler = () => {
        util.navigateToPage(customerPortal)
    }

    const pricingTable = 
        <stripe-pricing-table
            pricing-table-id={priceTableId}
            publishable-key={publishableKey}
            customer-email={email}
        >
        </stripe-pricing-table>

    const subscriptionDetails =
      <BigMessage btn={{title: "Manage your subscription", page: customerPortal}}>
        You already have Premium subscription
      </BigMessage>

    const content = isFree ? pricingTable : subscriptionDetails

    return content
}

export default PricingTable