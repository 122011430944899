import React from "react"
import * as locationStyle from "./locations.module.css"
import { FiDownload } from "react-icons/fi"
import { BiArchiveOut, BiPurchaseTagAlt } from "react-icons/bi"
import apiClient from "../../../../js/apiclient"
import "/node_modules/flag-icons/css/flag-icons.min.css"
import util from "../../../../js/util"
import { useDispatch } from "react-redux"
import { setPlansView } from "../../../../state/hostedVpnSlice"
import eventemitter from "../../../../js/eventemitter"
import Tippy from "@tippyjs/react"
import "tippy.js/dist/tippy.css" // optional

const getConfigFile = (locationId) => {
  //const dispatchLocations = window.dockovpnStoreApi.dispatchLocations
  eventemitter.emit("onDownload")

  util.downloadLocationConfig(locationId)
    .then(obj => eventemitter.emit("onDownloadData", obj))
    .catch(err_ => eventemitter.emit("onDownloadError"))
    .then(res_ => {
      apiClient.getLocations()
        .then(response => response.json())
        .then(locs => {
          console.log(locs)
        })
    })
}

const UpgradePlanBtn = () => {
  const dispatch = useDispatch()

  const clickHandler = (_event) => {
    dispatch(setPlansView())
  }

  return(
    <div className={locationStyle.upgradeBtn} onClick={clickHandler}>
      <Tippy content="Upgrade your current plan to Premium to access all locations"><span><BiPurchaseTagAlt /></span></Tippy>
    </div>
  )
}

const DownloadBtn = ({locationId}) => {
  const clickHandler = (_event) => {
    getConfigFile(locationId)
  }

  return(
    <div className={locationStyle.downloadBtn} onClick={clickHandler}>
      <Tippy content="Download a new config for this location"><span><FiDownload /></span></Tippy>
    </div>
  )
}

const DownloadAgainBtn = ({locationId}) => {
  const clickHandler = (_event) => {
    getConfigFile(locationId)
  }

  return(
    <div className={locationStyle.downloadBtn} onClick={clickHandler}>
      <Tippy content="Download config for this location again"><span><BiArchiveOut /></span></Tippy>
    </div>
  )
}

const CountryView = ({location}) => {
  const countryFlag = `${locationStyle.flag} fi-${location.countryCode.toLowerCase()} fis`
  const downloaded = location.hasOwnProperty("lastDownloaded")
  const actionBtn = location.requiresPlanUpgrade ? <UpgradePlanBtn /> : downloaded ? <DownloadAgainBtn locationId={location.locationId} /> : <DownloadBtn locationId={location.locationId} />

  return (
    <div className={locationStyle.locationView}>
      <div className={locationStyle.locationContainer}>
        <div className={locationStyle.flagContainer}>
          <div className={countryFlag}></div>
        </div>
        <div className={locationStyle.caption}>
          <p><strong>Country:</strong></p>
          <p><strong>City:</strong></p>
        </div>
        <div className={locationStyle.description}>
          <p>{location.country}</p>
          <p>{location.city}</p>
        </div>
        {actionBtn}
      </div>
    </div>
  )
}

export default CountryView