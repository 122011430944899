import React, { useEffect, useState } from "react";
import Loader from "../components/common/loader/loader";
import util from "../js/util";
import BaseLayout from "../components/layouts/baselayout";
import MainView from "../components/views/dashboard/mainview/mainview";
import Seo from "../components/seo";
import ScrollToTop from "react-scroll-up";

const Dashboard = () => {
    const [authenticated, setAuthenticated] = useState(false)
  
    useEffect(() => {
        util.checkLogin().then(res => {
            if(res) {
                setAuthenticated(true)
            }
        })
        const intervalId = setInterval(() => {
            util.checkLogin()
        }, 5000)

        return () => clearInterval(intervalId)
    }, [])

    let content = <Loader />
    if (authenticated) {
        content = <MainView />
    }

    return (
        <>
            <BaseLayout />
            { content }
            <ScrollToTop showUnder={1}>
                <span>UP</span>
            </ScrollToTop>
        </>
    )
}

export const Head = () => <Seo title="Dashboard">
    <script async src='https://js.stripe.com/v3/pricing-table.js'/>
</Seo>

export default Dashboard