import React from "react"
import { Image } from "react-bootstrap"
import * as styles from "./serviceitem.module.css"

const ServiceItem = ({selectedServiceId, data, onSelect}) => {
    const id = data.id
    const caption = data.name
    const iconPath = `/images/service/icons/${id}.svg`

    const handleClick = () => {
        onSelect(data)
    }

    const serviceItemStyle = selectedServiceId === id ? styles.serviceItem + " " + styles.selectedItem : styles.serviceItem + ""
    return (
        <div className={serviceItemStyle} onClick={handleClick}>
            <span className={styles.serviceItemIcon}><Image src={iconPath} width={25} height={25}/></span><span>{caption}</span>
        </div>
    )
}

export default ServiceItem