import React, { useState } from 'react';
import * as locationStyle from "./locations.module.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import _ from "lodash";
import util from '../../../../js/util';
import SubRegionView from './subregionview';

const RegionView = ({region}) => {
    const [regionName, locationsList] = region
    const [isOpen, setOpen] = useState(true)

    const toggleClickHandler = () => {
        setOpen(!isOpen)
    }

    const regionGroupArrowStyle = isOpen ? locationStyle.regionGroupArrowDown : locationStyle.regionGroupArrowRight

    const subregions = util.groupByField(locationsList, "sub-region")

    return (
        <div className={locationStyle.continentWrap}>
            <div className={ locationStyle.continentToggle } onClick={ toggleClickHandler }>
                <div className={ locationStyle.regionName }>{ regionName }</div><div className={ regionGroupArrowStyle } />
            </div>
            <div className={locationStyle.regionView} style={{
                display: isOpen ? "block" : "none"
            }}>
                { subregions.map((loc, i) => <SubRegionView subRegion={loc} key={i}/>) }
            </div>
        </div>
    )
}

export default RegionView