import React, { useEffect, useState } from 'react';
import * as locationStyle from "./locations.module.css";
import apiClient from '../../../../js/apiclient';
import { useDispatch } from 'react-redux';
import { setLocations } from '../../../../state/locationsSlice';
import eventemitter from '../../../../js/eventemitter';
import { BiSolidError } from "react-icons/bi";

const removePopup = () => {
    eventemitter.emit("onModalClose")
}

const ErrorWindow = ({errorData}) => {
    const errMsg = errorData.errMsg

    return (
        <div className={locationStyle.downloadPopup}>
            <div style={{
                width: "100%",
                height: "100%",
                position: "relative"
            }}>
                <div style={{
                    color: 'red',
                    fontSize: '4.5rem',
                    textAlign: 'center',
                    padding: '1rem'
                }}><span><BiSolidError /></span></div>
                <div style={{
                    fontSize: '1.35rem',
                    lineHeight: 'normal',
                    textAlign: 'center'
                }}>{ errMsg }</div>
                <div style={{
                    width: 'fit-content',
                    height: 'fit-content',
                    position: 'absolute',
                    bottom: '0',
                    left: '50%',
                    transform: 'translate(-50%, 0)'
                }}>
                    <div className={locationStyle.downloadLink} onClick={() => removePopup()}>CLOSE</div>
                </div>
            </div>
        </div>
    )
}

const DownloadWindow = ({downloadData}) => {
    const fileDownloadLink = "fileDownloadLink"

    const downloadLinkClickHandler = (event_) => {
        removePopup()
    }

    useEffect(() => {
        const downloadTimeoutId = setTimeout(() => {
            document.getElementById(fileDownloadLink).click()
        }, 500)

        return () => {
            clearTimeout(downloadTimeoutId)
        }
    }, [])

    return (
        <div id="fileContainer" className={locationStyle.downloadPopup}>
            <div style={{
                width: "100%",
                height: "100%",
                position: "relative"
            }}>
                <div>
                    <p>Download will start automatically in a moment. If download doesnt start, plese click "Download" button below.</p>
                </div>
                <div className={ locationStyle.progressDecor + " " + locationStyle.rotation }></div> 
                <div style={{
                    width: 'fit-content',
                    height: 'fit-content',
                    position: 'absolute',
                    bottom: '0',
                    left: '50%',
                    transform: 'translate(-50%, 0)'
                }}>
                    <a id={fileDownloadLink} href={downloadData.url} download={downloadData.fileName} onClick={downloadLinkClickHandler}>
                        <div className={locationStyle.downloadLink}>DOWNLOAD</div>
                    </a>
                </div>
            </div>
        </div> 
    )
}

const DownloadModal = () => {
    const dispatch = useDispatch()
    const [downnloadWindowData, setDownnloadWindowData] = useState(null)
    const isDownloadWindow = downnloadWindowData !== null && downnloadWindowData.url !== undefined
    const isErrorWindow = downnloadWindowData !== null && downnloadWindowData.errMsg !== undefined

    const onDownloadDataEvent = (eventData) => {
        const blob = eventData.data
        const url = window.URL.createObjectURL(blob)
        const popupData = Object.assign(eventData, {url: url})

        apiClient.getLocations()
        .then(response => response.json())
        .then(locs => {
            console.log(locs)
            dispatch(setLocations(locs.locations))
        })

        setDownnloadWindowData(popupData)
    }

    const onDownloadErrorEvent = () => {
        setDownnloadWindowData(Object.create({errMsg: "Unexpected error occured during file download!"}))
    }

    useEffect(() => {
        const onDownloadDataToken = eventemitter.addListener("onDownloadData", onDownloadDataEvent)
        const onDownloadErrorToken = eventemitter.addListener("onDownloadError", onDownloadErrorEvent)

        const clearTimeoutId = setTimeout(() => {
            removePopup()
        }, 15000)

        return () => {
            onDownloadDataToken.remove()
            onDownloadErrorToken.remove()
            clearTimeout(clearTimeoutId)
        }
    }, [])

    return (
        <div className={locationStyle.downloadPopupModal}>
            { isDownloadWindow && <DownloadWindow downloadData={downnloadWindowData}/> }
            { isErrorWindow && <ErrorWindow errorData={downnloadWindowData}/> }
        </div>
    )
}

const DownloadPopup = () => {
    const [isDownloadModal, setDownloadModal] = useState(false)

    const onDownloadEvent = () => {
        setDownloadModal(true)
    }

    const onModalCloseEvent = () => {
        setDownloadModal(false)
    }

    useEffect(() => {
        const onDownloadToken = eventemitter.addListener("onDownload", onDownloadEvent)
        const onModalCloseToken = eventemitter.addListener("onModalClose", onModalCloseEvent)

        return () => {
            onDownloadToken.remove()
            onModalCloseToken.remove()
        }
    }, [])

    return (
        <>
            { isDownloadModal && <DownloadModal /> }
        </>
    )
}

export default DownloadPopup