import React, { useEffect } from 'react';
import * as locationStyle from "./locations.module.css";
import apiClient from '../../../../js/apiclient';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import GetCountryByCode from '../../../../js/countriesiso';
import _ from "lodash";
import util from '../../../../js/util';
import { useDispatch, useSelector } from 'react-redux';
import { setLocations } from '../../../../state/locationsSlice';
import RegionView from './regionview';
import DownloadPopup from './downloadpopup';

const Locations = () => {
    const dispatch = useDispatch()
    const locationsList = useSelector((state) => state.locations.list)

    useEffect(() => {
        apiClient.getLocations()
        .then(response => response.json())
        .then(locs => {
            console.log(locs)
            dispatch(setLocations(locs.locations))
        })
    }, [])

    //TODO: useMemo
    const extLocationsList = locationsList.map(loc => {
        const countryIso = GetCountryByCode(loc.countryCode)
        const extra = _.pick(countryIso, ["region", "sub-region"])

        const mutObj = Object.create({})
        for ( var propKey in loc ) {
            mutObj[ propKey ] = loc[ propKey ];
        }

        return Object.assign(mutObj, extra)
    })

    const regions = util.groupByField(extLocationsList, "region")
    
    return (
        <>
            <DownloadPopup />
            <div className={locationStyle.topContainer}>
                { regions.map((reg, i) => <RegionView region={reg} key={i}/>) }
            </div>
        </>
    )
}

export default Locations