import React, { useEffect, useState } from 'react'
import Locations from '../locations/locations'
import HostedVpnBanner from '../../../banners/hostedvpn'
import PricingTable from '../../../common/stripe/pricingtab'
import { useDispatch, useSelector } from 'react-redux'
import { setLocationsView } from '../../../../state/hostedVpnSlice'
import CommonBanner from '../../../banners/commonbanner'
import { IoMdArrowRoundBack } from "react-icons/io";
import * as styles from "./products.module.css"
import apiClient from '../../../../js/apiclient'
import BackLink from '../../../banners/backlink'

const HostedVpn = () => {
    const dispatch = useDispatch()
    const [tiers, setTiers] = useState([])
    const currentTier = tiers === undefined ? undefined : tiers.find(tier => tier.isInUse)
    const isFreeTier = currentTier === undefined ? false : !currentTier.purchasable
    const locationViewId = "locationsView"
    const plansViewId = "plansView"
    const currentView = useSelector((state) => state.hostedVpn.view)

    useEffect(() => {
        apiClient.getTiers()
        .then(resp => resp.json())
        .then(tiers => setTiers(tiers))

        return () => dispatch(setLocationsView())
    }, [])

    const getViewById = viewId => {
        if (viewId === locationViewId) {
            return locationView
        } else if (viewId === plansViewId) {
            return plansView
        } else {
            return <></>
        }
    }

    const locationView =
        <>
            <HostedVpnBanner tiers={tiers} />
            <Locations />
        </>

    const plansView = 
        <div className={styles.plansContainer}>
            <CommonBanner title='Hosted VPN Premium' />
            <BackLink action={() => dispatch(setLocationsView())} />
            <PricingTable isFree={isFreeTier} />
        </div>
    
    return (
        <>
            { getViewById(currentView) }
        </>
    )
}

export default HostedVpn