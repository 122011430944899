// extracted by mini-css-extract-plugin
export var caption = "locations-module--caption--b4b98";
export var continentToggle = "locations-module--continentToggle--1af8f";
export var continentWrap = "locations-module--continentWrap--2d10f";
export var description = "locations-module--description--914be";
export var downloadBtn = "locations-module--downloadBtn--d3bc0";
export var downloadLink = "locations-module--downloadLink--b2054";
export var downloadPopup = "locations-module--downloadPopup--a79ae";
export var downloadPopupModal = "locations-module--downloadPopupModal--29ede";
export var flag = "locations-module--flag--665d7";
export var flagContainer = "locations-module--flagContainer--98271";
export var locationContainer = "locations-module--locationContainer--d06b1";
export var locationView = "locations-module--locationView--5c832";
export var progressDecor = "locations-module--progressDecor--f2778";
export var regionGroupArrowDown = "locations-module--regionGroupArrowDown--05ab0";
export var regionGroupArrowRight = "locations-module--regionGroupArrowRight--5cf2c";
export var regionName = "locations-module--regionName--77d0b";
export var regionView = "locations-module--regionView--5870b";
export var rotation = "locations-module--rotation--70a2f";
export var subregion = "locations-module--subregion--5b8f2";
export var subregionName = "locations-module--subregionName--54273";
export var subregionView = "locations-module--subregionView--38de4";
export var topContainer = "locations-module--topContainer--1cf78";
export var upgradeBtn = "locations-module--upgradeBtn--2a6b2";