import React, { useEffect, useState } from 'react';
import apiClient from '../../../../js/apiclient';
import { TiDelete } from "react-icons/ti";
import * as styles from "./slugview.module.css";

/* Config
    locationId: String,
    city: String,
    countryCode: String,
    configName: String,
    clientId: String,
    userName: String,
    timeCreated: String,
    timeExpires: String,
 */
const ConfigItemView = ({config, removeConfigHandler}) => {
    const handleDeleteConfig = () => {
        if (window.confirm(`Are you sure you want to delete ${config.clientId} configuration?`)) {
            removeConfigHandler(config.locationId, config.userName)
        }
    }

    return (
        <tr>
            <td>{config.userName}</td>
            <td>{config.clientId}</td>
            <td>{config.timeCreated}</td>
            <td>{config.timeExpires}</td>
            <td>
                <div className={styles.removeConfig} onClick={handleDeleteConfig}>
                    <span><TiDelete /></span>
                </div>
            </td>
        </tr>
    )
}

const ConfigView = ({locationId}) => {
    const [configs, setConfigs] = useState([])

    useEffect(() => {
        apiClient.getLocationConfigs(locationId)
        .then(res => res.json())
        .then(data => setConfigs(data))
    }, [])

    const removeConfigHandler = (locationId, userName) => {
        apiClient.removeConfig(locationId, userName)
        .then(res_ => {
            apiClient.getLocationConfigs(locationId)
            .then(res => res.json())
            .then(data => setConfigs(data))
        })
    }

    return (
        <div>
            <table>
                <thead>
                    <tr>
                        <th>userName</th>
                        <th>clientId</th>
                        <th>timeCreated</th>
                        <th>timeExpires</th>
                        <th>Remove</th>
                    </tr>
                </thead>
                <tbody>
                    { configs.map((config, i) => <ConfigItemView config={config} removeConfigHandler={removeConfigHandler} key={i}/>) }
                </tbody>
            </table>
        </div>
    )
}

export default ConfigView