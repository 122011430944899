import React, { useState } from 'react'
import apiClient from '../../../js/apiclient'
import * as styles from "./signupform.module.css"

const registrationError = <>
    <div className={ styles.authError }>
        <p>User with such userName already exists.</p>
    </div>
</>

const QuickHostedVpnUser = () => {
    const [newUser, setNewUser] = useState({
        firstName: "",
        lastName: "",
        email: "",
        userName: "",
        userPassword: ""
    })
    const [hasRegistrationError, setHasRegistrationError] = useState(false)

    const handleSubmit = (event) => {
        event.preventDefault()

        apiClient.quickAddHostedVpnUser(newUser).then(response => {
            if (response.status === 500) {
                setHasRegistrationError(true)
            } else {
                console.log("Add user response: ", response)
            }
        })
    }

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name

        const updatedNewUser = Object.assign({}, newUser)
        updatedNewUser[name] = value

        setNewUser(updatedNewUser)
    }

    return (
        <div id="signupquick" className={ styles.quicksignup }>
            <h3>Quick Add User</h3>
            { hasRegistrationError  && registrationError }
            <form onSubmit={handleSubmit}>
                <label>
                First name:
                <input
                    name="firstName"
                    type="text"
                    placeholder="first name"
                    onChange={handleInputChange} />
                </label>
                <br />
                <label>
                Last name:
                <input
                    name="lastName"
                    type="text"
                    placeholder="last name"
                    onChange={handleInputChange} />
                </label>
                <br />
                <label>
                Email:
                <input
                    name="email"
                    type="text"
                    placeholder="email"
                    onChange={handleInputChange} />
                </label>
                <br />
                <label>
                User name:
                <input
                    name="userName"
                    type="text"
                    placeholder="user"
                    onChange={handleInputChange} />
                </label>
                <br />
                <label>
                Password:
                <input
                    name="userPassword"
                    type="text"
                    placeholder="password"
                    onChange={handleInputChange} />
                </label>
                <input type="submit" value="Submit"/>
            </form>
            { hasRegistrationError  && <div style={{
                height: "35px"
            }}></div> }
            <div className={ styles.suiteBlock }></div>
        </div>
    )
}

export default QuickHostedVpnUser