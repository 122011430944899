import React, { useEffect, useState } from 'react';
import apiClient from '../../../../js/apiclient';
import * as styles from "./slugview.module.css";
import ConfigView from './configview';
import { RiRestartFill } from "react-icons/ri";

/* Slug data
    locationId: String,
    remoteHost: String,
    city: String,
    country: String,
    countryCode: String,
    chunk: String,
    slugPort: Int,
    tls: Option[Boolean],
*/
const SlugItemiew = ({slug, respawnHandler}) => {
    const [isOpen, setOpen] = useState(false)

    const toggleClickHandler = () => {
        setOpen(!isOpen)
    }

    const handleRespawnClick = () => {
        if (window.confirm(`Are you sure you want to respawn ${slug.locationId}?`)) {
            respawnHandler(slug.locationId)
        }
    }

    const configGroupArrowStyle = isOpen ? styles.configGroupArrowDown : styles.configGroupArrowRight

    const locationInfoView = <>
        <tr>
            <td>{ slug.locationId }</td>
            <td>{ slug.country }</td>
            <td>{ slug.city }</td>
            <td>{ slug.remoteHost }</td>
            <td>{ slug.slugPort }</td>
            <td>{ slug.slugVersion }</td>
            <td>{ slug.dockovpnVersion }</td>
            <td>{ 0 }</td>
            <td>{ slug.cpuUsage }</td>
            <td>{ slug.memUsage }</td>
            <td>
                <div className={styles.respawnSlug} onClick={handleRespawnClick}>
                    <span><RiRestartFill /></span>
                </div>
            </td>
            <td>
                <div className={configGroupArrowStyle} onClick={toggleClickHandler}></div>
            </td>
        </tr>
    </>

    const configListView = <>
        { locationInfoView }
        <tr>
            <td colSpan={11}>
                <ConfigView locationId={slug.locationId}/>
            </td>
        </tr>
    </>

    const currentView = isOpen ? configListView : locationInfoView
    
    return (
        <>{ currentView }</>
    )
}

const SlugsView = () => {
    const [slugs, setSlugs] = useState([])

    useEffect(() => {
        apiClient.getSlugs()
        .then(res => res.json())
        .then(data => setSlugs(data))
    }, [])

    const respawnHandler = (locationId) => {
        apiClient.respawnSlug(locationId)
        .then(res_ => {
            apiClient.getSlugs()
            .then(res => res.json())
            .then(data => setSlugs(data))
        })
    }

    return (
        <div className={styles.slugsView}>
            <table>
                <colgroup>
                    <col colSpan={1} style={{width: "5%"}} />
                    <col colSpan={1} style={{width: "5%"}} />
                    <col colSpan={1} style={{width: "5%"}} />
                    <col colSpan={1} style={{width: "5%"}} />
                    <col colSpan={1} style={{width: "5%"}} />
                    <col colSpan={1} style={{width: "5%"}} />
                    <col colSpan={1} style={{width: "5%"}} />
                    <col colSpan={1} style={{width: "5%"}} />
                    <col colSpan={1} style={{width: "5%"}} />
                    <col colSpan={1} style={{width: "5%"}} />
                    <col colSpan={1} style={{width: "5%"}} />
                    <col colSpan={1} style={{width: "5%"}} />
                </colgroup>
                <thead>
                    <tr>
                        <th>locationId</th>
                        <th>country</th>
                        <th>city</th>
                        <th>remoteHost</th>
                        <th>slugPort</th>
                        <th>slugVersion</th>
                        <th>dockovpnVersion</th>
                        <th>online users</th>
                        <th>cpu %</th>
                        <th>mem %</th>
                        <th>Respawn</th>
                        <th>Configs</th>
                    </tr>
                </thead>
                <tbody>
                    { slugs.map((slug, i) => <SlugItemiew slug={slug} respawnHandler={respawnHandler} key={i}/>) }
                </tbody>
            </table>
        </div>
    )
}

export default SlugsView