// extracted by mini-css-extract-plugin
export var btnContainer = "mainview-module--btnContainer--fff43";
export var dashboard = "mainview-module--dashboard--648bd";
export var servicesCaption = "mainview-module--servicesCaption--24b57";
export var upArrowBg = "mainview-module--upArrowBg--96cdb";
export var userName = "mainview-module--userName--70fda";
export var userOptions = "mainview-module--userOptions--2e193";
export var userOptsBtn = "mainview-module--userOptsBtn--78462";
export var userOptsMenu = "mainview-module--userOptsMenu--34f61";
export var userThumb = "mainview-module--userThumb--76cb1";
export var userWrap = "mainview-module--userWrap--d56ad";