import React, { useState } from 'react'
import QuickHostedVpnUser from '../../../forms/signupform/quickHostedVpnUser'
import SlugsView from '../../admin/slugs/slugview'

const AdminPanel = () => { 
  const usersViewName = "Users"
  const slugsViewName = "Slugs"
  const subscriptionsViewName = "Subscriptions"

  const nameToViewMap = new Map()
  nameToViewMap.set(usersViewName, <QuickHostedVpnUser />)
  nameToViewMap.set(slugsViewName, <SlugsView />)
  nameToViewMap.set(subscriptionsViewName, <></>)

  const [currentViewName, setCurrentViewName] = useState(usersViewName)
  const viewList = Array.from(nameToViewMap.keys())
  const currentView = nameToViewMap.get(currentViewName)

  return (
    <div>
        <h2>Admin Panel</h2>
        <div style={{
          textAlign: "center",
          marginBottom: "2rem"
        }}>
        { viewList.map((k, i) => <div style={{
          display: "inline-block",
          margin: "1rem",
          color: "blue",
          fontSize: "1.5rem",
          cursor: "pointer"
        }} key={i} onClick={() => setCurrentViewName(k)}><span>[{k}]</span></div>) }
        </div>
        { currentView }
    </div>
  )
}

export default AdminPanel