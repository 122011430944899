import React from 'react';
import * as locationStyle from "./locations.module.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import _ from "lodash";
import CountryView from './countryview';

const SubRegionView = ({subRegion}) => {
    const [subRegionName, locationsList] = subRegion

    return (
        <div className={locationStyle.subregion}>
            <div className={locationStyle.subregionName}>{ subRegionName }</div>
            <div className={locationStyle.subregionView}>
                { locationsList.map(loc => <CountryView location={loc} key={loc.locationId}/>) }
            </div>
        </div>
    )
}

export default SubRegionView